/*fonts*/
@font-face {
  font-family: 'MontserratBold';
  src: url(fonts/MontserratBold.ttf);
}

@font-face {
  font-family: 'MontserratMedium';
  src: url(fonts/MontserratMedium.ttf);
}

@font-face {
  font-family: 'DomaineDisplaySemiboldRegular';
  src: url(fonts/DomaineDisplaySemiboldRegular.ttf);
}

@font-face {
  font-family: 'AlYermook';
  src: url(fonts/AlYermook.ttf);
}

@font-face {
  font-family: 'GESSTwoBold';
  src: url(fonts/GESSTwoBold.otf);
}

@font-face {
  font-family: 'GESSTextLight';
  src: url(fonts/GESSTextLight.otf);
}

.ff-AlYermook {
  font-family: 'AlYermook';
}

.ff-GESSTwoBold {
  font-family: 'GESSTwoBold';
}

.ff-GESSTextLight {
  font-family: 'GESSTextLight';
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input,
textarea,
select {
  border-color: #e1d5c6 !important;
  /* Change the background color to yellow (you can use any valid color value) */
}

.primaryEN {
  color: #b89e7e !important;
  font-family: 'MontserratMedium';
}
.primaryAR {
  color: #b89e7e !important;
  font-family: 'GESSTextLight';
}

.secondaryEN {
  color: #48688f !important;
  font-family: "DomaineDisplaySemiboldRegular";
}
.secondaryAR {
  color: #48688f !important;
  font-family: "GESSTwoBold";
}
.dangerEN {
  color: #8f2933 !important;
  font-family: 'MontserratMedium';
}
.dangerAR {
  color: #8f2933 !important;
  font-family: 'GESSTextLight';
}
.infoEN {
  color: #a56b4e !important;
  font-family: 'MontserratMedium';
}
.infoAR {
  color: #a56b4e !important;
  font-family: 'GESSTextLight';
}

.primary-bg {
  background-color: #b89e7e !important;
  border-color: #b89e7e !important;
  font-family: 'MontserratMedium' !important;
}

.secondary-bg {
  background-color: #48688f !important;
  border-color: #48688f !important;
  font-family: 'MontserratMedium' !important;
}

.fs-7 {
  font-size: 0.8rem;
}

.footer {
  background-color: #a56b4e;
  font-family: 'MontserratMedium';
}

.social-icons {
  width: 15px;
}

.scroll-mt {
  scroll-margin-top: 50px;
}

.nav-link-activeEN {
  font-family: 'MontserratBold';
  color: #48688f !important;
}
.nav-link-activeAR {
  font-family: 'GESSTwoBold';
  color: #48688f !important;
}

.header-logo-start {
  width: 100px;
}

.header-logo-end {
  width: 40px;
  height: 40px;
}

.section-slider {
  height: 90vh;
}

.slider-image {
  width: 100%;
  height: 90vh;
}

.carousel-indicators {
  display: inline-grid !important;
  top: 35%;
  right: unset !important;
  bottom: unset !important;
  left: unset !important;
  margin-left: 20px !important;

}

.carousel-indicators [data-bs-target]:nth-child(1) {
  background-image: url("https://awjftp.blob.core.windows.net/awjcontents/websites/awani/spot1.webp") !important;
  background-size: contain;
  background-repeat: no-repeat;
  width: 10px !important;
  height: 10px !important;
  background-color: unset !important;
  margin-bottom: -15px !important;
}

.carousel-indicators [data-bs-target]:nth-child(2) {
  background-image: url("https://awjftp.blob.core.windows.net/awjcontents/websites/awani/spot2.webp") !important;
  background-size: contain;
  background-repeat: no-repeat;
  width: 10px !important;
  height: 10px !important;
  background-color: unset !important;
  margin-bottom: -15px !important;
}

.carousel-indicators [data-bs-target]:nth-child(3) {
  background-image: url("https://awjftp.blob.core.windows.net/awjcontents/websites/awani/spot3.webp") !important;
  background-size: contain;
  background-repeat: no-repeat;
  width: 10px !important;
  height: 10px !important;
  background-color: unset !important;
  margin-bottom: -15px !important;
}

.carousel-indicators [data-bs-target]:nth-child(4) {
  background-image: url("https://awjftp.blob.core.windows.net/awjcontents/websites/awani/spot4.webp") !important;
  background-size: contain;
  background-repeat: no-repeat;
  width: 10px !important;
  height: 10px !important;
  background-color: unset !important;
  margin-bottom: -15px !important;
}

.carousel-indicators [data-bs-target]:nth-child(5) {
  background-image: url("https://awjftp.blob.core.windows.net/awjcontents/websites/awani/spot5.webp") !important;
  background-size: contain;
  background-repeat: no-repeat;
  width: 10px !important;
  height: 10px !important;
  background-color: unset !important;
  margin-bottom: -15px !important;
}

.carousel-indicators [data-bs-target]:nth-child(6) {
  background-image: url("https://awjftp.blob.core.windows.net/awjcontents/websites/awani/spot1.webp") !important;
  background-size: contain;
  background-repeat: no-repeat;
  width: 10px !important;
  height: 10px !important;
  background-color: unset !important;
  margin-bottom: -15px !important;
}

.section-slider {
  position: relative;
}

.slider-overlay-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.video-background {
  position: relative;
  width: 100%;
  height: 85vh;
  overflow: hidden;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.px-section {
  max-width: 730px !important;
}

.px-lg-section {
  padding-right: 7rem !important;
  padding-left: 7rem !important;
}

.section1-side-image {
  max-width: 300px;
}

.section2-img {
  width: 200px;
}

.section2-img:hover {
  transform: scale(1.2);
  /* Increase the scale to zoom in (1.1 means 110% of the original size) */
}

.section2-bg {
  /* The image used */
  background-image: url("https://awjftp.blob.core.windows.net/awjcontents/websites/awani/section2-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.section3-bg {
  /* The image used */
  background-image: url("https://awjftp.blob.core.windows.net/awjcontents/websites/awani/section3-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.section5-bg {
  /* The image used */
  /* background-image: url("https://awjftp.blob.core.windows.net/awjcontents/websites/awani/section5-bg.webp");
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover; */
}

.section5-text-bg {
  /* The image used */
  background-image: url("https://awjftp.blob.core.windows.net/awjcontents/websites/awani/section5-text-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.events-form-button {
  justify-content: flex-end;
}

.alert-button {
  width: 50%;
}

.section6 {
  /* The image used */
  /* background-image: url("https://awjftp.blob.core.windows.net/awjcontents/websites/awani/section6-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover; */
}

.location-separator {
  height: 100px;
  width: 3px !important;
  margin-top: 75px !important;
  color: #a56b4e99 !important;
  opacity: 1 !important;
}

.alert-frachise {
  background-color: #a56b4e99 !important;
  color: #fff !important;
  height: 30%;
}

.section3-img {
  position: relative;
}

.section3-img:before {
  content: '';
  display: block;
  position: absolute;
  height: 0%;
  width: 100%;
  bottom: 0;
  transition: height 0.5s ease-out;
  background: linear-gradient(to bottom, transparent 50%, #b89e7e 100%);
}

/* .section3-img:hover:before {
  height: 100%;
} */

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s;
  /* Add a smooth transition effect */
}


.overlay-content {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  font-size: 18px;
}

/* .section3-img:hover .overlay {
  opacity: 1;
} */

.mobile-btn {
  width: 25% !important;
}

.location-img {
  width: 75%;
  height: 50%;
}

.react-datepicker {
  border: none !important;
}

.react-datepicker__header {
  background-color: unset !important;
  border: none !important;
}

.react-datepicker__current-month,
.react-datepicker__day-name {
  color: #b89e7e !important;
}

.react-datepicker__day {
  color: #48688f !important;
}

.react-datepicker__day--selected {
  color: #fff !important;
  background-color: #b89e7e !important;
  border-radius: 50% !important;
}

.react-datepicker__day--outside-month {
  color: #e1d5c6 !important;
}

.select-time-min-w {
  min-width: 100px !important;
}

.guests-max-w {
  max-width: 90px !important;
}

.navbar-toggler-icon {
  background-image: url('https://awjftp.blob.core.windows.net/awjcontents/websites/awani/hamburger.webp') !important;
}

.d-mobile {
  display: none;
}

.pt-6{
  padding-top: 4rem !important;
}

/* Small devices (e.g., smartphones) */
@media (max-width: 576px) {
  .float-mobile-end {
    float: right !important;
  }

  .slider-overlay-image {
    object-fit: cover;
  }

  .carousel-indicators {
    top: 15% !important;
  }

  .carousel-indicators [data-bs-target]:nth-child(1),
  .carousel-indicators [data-bs-target]:nth-child(2),
  .carousel-indicators [data-bs-target]:nth-child(3),
  .carousel-indicators [data-bs-target]:nth-child(4),
  .carousel-indicators [data-bs-target]:nth-child(5),
  .carousel-indicators [data-bs-target]:nth-child(6) {
    width: 10px !important;
    height: 10px !important;
  }

  .section2-img {
    width: 130px;
  }

  .px-lg-section {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .mobile-mx-1 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .mobile-mt-2 {
    margin-top: .5rem !important;
  }

  .mobile-mb-1 {
    margin-bottom: .25rem !important;
  }

  .mobile-btn {
    width: 50% !important;
  }

  .mobile-location-fs {
    font-size: 11px;
  }

  .events-form-button {
    justify-content: center;
  }

  .alert-button {
    width: 100%;
  }

  .location-img {
    height: 30% !important;
  }

  .section3-bg {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .section4 {
    text-align: center;
  }

  .section5-bg {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .section5-text-bg {
    background-image: none;
  }

  .section-slider,
  .slider-image {
    height: 30vh;
  }

  .scroll-mt {
    scroll-margin-top: 60px !important;
  }

  .video-background {
    height: 30vh;
  }

  .d-desktop {
    display: none;
  }

  .d-mobile {
    display: block;
  }
}